export const youtubeVideoPreview = {
    data() {
        return {
            vValidationPattern: /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/,
            vExtractPattern: /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/,
            youTubeApiKey: 'AIzaSyBKv65HFe6ZMwRy_dXmo749YdzTl0cGDTc',
            youTubeApiUrl: 'https://www.googleapis.com/youtube/v3/videos',
            youtubeVideo: {
                youTubeVideoId: null,
                youTubeVideoUrl: null,
                youtubeVideoTitle: null,
                youtubeVideoDescription: null,
                startViews: null,
                startViewsPreview: null,
                startLikes: null,
                startDislikes: null,
                startFavorite: null,
                startComents:null,
                youTubeVideoData: null
            }
        }
    },
    methods:{ 
        checkIsValidYouTubeLink(videoUrl) {
            if(! this.vValidationPattern.test(videoUrl))
                return false;

            const videoID = this.extractVideoId(videoUrl);
 
            if(!videoID)
                return false;

            return true;
        },
        extractVideoId(videoUrl) {
            let match = videoUrl.match(this.vExtractPattern);

            if(! match) {
                return;
            }
            
            if(match[7] && match[7].length != 11) {
                return;
            }

            this.youtubeVideo.youTubeVideoId = match[7];

            return this.youtubeVideo.youTubeVideoId;
        },
        getYouTubeVideoInfoFromUrl(videoUrl) {
            
            this.getYouTubeVideoInfo(this.extractVideoId(videoUrl));
        },
        getYouTubePreview(snippet) {
            if(snippet && snippet.thumbnails && snippet.thumbnails.standard) {
                return snippet.thumbnails.standard.url;
            }

            if(snippet && snippet.high && snippet.thumbnails.high) {
                return snippet.thumbnails.medium.url;
            }

            if(snippet && snippet.thumbnails && snippet.thumbnails.medium) {
                return snippet.thumbnails.medium.url;
            }

            if(snippet && snippet.thumbnails && snippet.thumbnails.default) {
                return snippet.thumbnails.default.url;
            }
        },
        getYouTubeVideoInfo(videoId) {
            const _this = this;

            const xhttp = new XMLHttpRequest();

            xhttp.onreadystatechange = function() {
                if (this.readyState == 4 && this.status == 200) {
                    const data = JSON.parse(this.response)
                    if(data.items[0]) {
                        _this.youtubeVideo.youTubeVideoUrl =  _this.getYouTubePreview(data.items[0].snippet); 
                        _this.youtubeVideo.youtubeVideoTitle = data.items[0].snippet.title;
                        _this.youtubeVideo.youtubeVideoDescription = data.items[0].snippet.description;
                        _this.youtubeVideo.startViews = data.items[0].statistics.viewCount;
                        _this.youtubeVideo.startViewsPreview = parseInt(_this.youtubeVideo.startViews).toLocaleString('ru-RU');
                        _this.youtubeVideo.startLikes = data.items[0].statistics.likeCount;
                        _this.youtubeVideo.startDislikes = data.items[0].statistics.dislikeCount;
                        _this.youtubeVideo.startFavorite = data.items[0].statistics.favoriteCount;
                        _this.youtubeVideo.startComents =  data.items[0].statistics.commentCount;
                        _this.youtubeVideo.youTubeVideoData = new Date(data.items[0].snippet.publishedAt);
                    } else {
                        _this.youtubeVideo.youTubeVideoUrl = null; 
                        _this.youtubeVideo.youtubeVideoTitle = null;
                        _this.youtubeVideo.youtubeVideoDescription = null;
                        _this.youtubeVideo.startViews = 0;
                        _this.youtubeVideo.startLikes = 0;
                        _this.youtubeVideo.startDislikes = 0;
                        _this.youtubeVideo.startFavorite = 0;
                        _this.youtubeVideo.startComents = 0;
                    }
                }
            };
            xhttp.open("GET", this.youTubeApiUrl + "?key="+ this.youTubeApiKey +"&part=snippet,statistics&id=" + videoId, true);
            xhttp.send();
        }
    }
}