<template>
    <div>
        <nav-admin/>
        <create-video ref="createVideo" @saved="saved" />
        <delete-video ref="deleteVideo" @saved="saved" />
        <div class="container">
            <h3 class="h1 pt-4">
                <span>Video show</span>
                <button v-if="$auth.check(admin.id)" @click="create" class="btn btn-primary float-right">Create</button>
            </h3>
            <table v-if="!isError && videos.length" class="table mt-3">
                <thead>
                    <tr>
                        <th></th>
                        <th>Date</th>
                        <th colspan="2">Title</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="item in videos" v-bind:key="item.id">
                        <td>
                            <img style="width: 100px;" :src="item.thumbnail_url" :alt="item.title">
                        </td>
                        <td><div style="width: 200px">{{ convertBrouserTZ(item.created_at) }}</div></td>
                        <td>{{ item.title }}</td>
                        <td>
                            <div class="float-right" style="width: 270px">
                                <button v-if="$auth.check(admin.id)" class="btn btn-danger float-right"  @click="deleteVideo(item.id)">Delete</button>
                                <button v-if="!item.show_id" class="btn btn-primary float-right mr-2" @click="addToShow(item.id)">Show</button>
                                <button v-if="item.show_id" class="btn btn-warning float-right mr-2" @click="deleteToShow(item.show_id)">Hide</button>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
            <p class="h4 pt-3" v-if="videos.length == 0">Nothing found!</p>
        </div>
    </div>
</template>
<script>
import navAdmin from './../../components/nav-admin';
import createVideo from './CreateModal';
import deleteVideo from './DeleteModal';

import { userType } from './../../mixins/user-type.mixin';
import { timeZone } from './../../mixins/time-zone.mixin';
import { notificationMixin } from './../../mixins/notification.mixin';


export default {
    data() {
        return {
            videos: [],
            skip: 0,
            take: 10
        }
    },
    mixins: [userType, timeZone, notificationMixin],
    components: {
        navAdmin,
        createVideo,
        deleteVideo
    },
    methods: {
        get() {
              this.$http({ url: 'video/skip/' + this.skip + '/take/' + this.take, method: 'GET'})
                .then(resp => this.videos = resp.data.videos);
        },
        create() {
            this.$refs.createVideo.show();
        },
        addToShow(videoId) {
            this.$http.post('video-show/' + videoId).then(
                () => {
                    this.successNtf("Success!", "Video added succesfully!");
                    this.get();
                }, 
                () => this.errorNtf("Something went wrong!", "Internal server error!")
            );
        },
        deleteToShow(showVideoId) {
            this.$http.delete('video-show/' + showVideoId).then(
                () => { 
                    this.successNtf("Success!", "Video unbinded succesfully!")
                    this.get();    
                }, 
                () => this.errorNtf("Something went wrong!", "Internal server error!")
            );
        },
        saved() {
            this.get();
        },
        deleteVideo(id) {
            this.$refs.deleteVideo.videoId = id;
            this.$refs.deleteVideo.show();
        }
    },
    mounted() {
        this.get();
    }
}
</script>