<template>
    <b-modal :id="windowId" title="Add Video">
        <b-container fluid>
            <admin-input v-model="videoUrl" title="Youtube Video Url" />
            <div v-if="youtubeVideo.youtubeVideoTitle">
                <b-img thumbnail fluid :src="youtubeVideo.youTubeVideoUrl" :alt="youtubeVideo.youtubeVideoTitle" />
                <admin-input v-model="youtubeVideo.youTubeVideoId" title="Youtube Video Id" :disable="true" name="youtube_id" :errors="reasonErrors" />
                <admin-input v-model="youtubeVideo.youtubeVideoTitle" title="Youtube Video Title" :disable="true" name="title" :errors="reasonErrors" />
                <admin-textarea v-model="youtubeVideo.youtubeVideoDescription" title="Youtube Video Title" :disable="true" />
            </div>
        </b-container>    
        <template v-slot:modal-footer="{ cancel }">
            <b-button variant="primary" @click="save" :disabled="!youtubeVideo.youtubeVideoTitle" >Save</b-button>
            <b-button  @click="cancel()">Cancel</b-button>
        </template>
    </b-modal>  
</template>
<script>

import adminInput from './../Form/dnd-admin-input';
import adminTextarea from './../Form/dnd-admin-textarea';
import { youtubeVideoPreview } from './../../mixins/youtube-v-preview.mixin';
import { notificationMixin } from './../../mixins/notification.mixin';

export default {
    components: {
        adminInput, 
        adminTextarea
    },
    mixins: [youtubeVideoPreview, notificationMixin],
    data() {
        return {
            windowId: 'add-video-window',
            videoUrl: null,
            reasonText: null,
            reasonErrors: []
        }
    },
    watch: {
        videoUrl(val) {
            this.youtubeVideo.youTubeVideoId = null;
            this.youtubeVideo.youtubeVideoTitle = null;
            this.youtubeVideo.youtubeVideoDescription = null;
            this.youtubeVideo.youTubeVideoUrl = null;
            this.getYouTubeVideoInfoFromUrl(val);
        }
    },
    methods: {
        show() {
            this.reasonErrors = [];
            this.videoUrl = null;
            this.youtubeVideo.youTubeVideoId = null;
            this.youtubeVideo.youtubeVideoTitle = null;
            this.youtubeVideo.youtubeVideoDescription = null;
            this.youtubeVideo.youTubeVideoUrl = null;
            this.$bvModal.show(this.windowId);
        },
        save() {
            this.$http.post('video', {
                'youtube_id': this.youtubeVideo.youTubeVideoId,
                'title': this.youtubeVideo.youtubeVideoTitle,
                'description': this.youtubeVideo.youtubeVideoDescription,
                'thumbnail_url': this.youtubeVideo.youTubeVideoUrl
            }).then(this.responseSuccess, this.responseError);
        },
        responseSuccess() {
            this.$bvModal.hide(this.windowId);
            this.successNtf("Success!", "Video saved succesfully!");
            this.$emit('saved', true);
        },
        responseError(res) {
            if(res.response.data.hasOwnProperty('errors')) {
                this.reasonErrors = res.response.data.errors;
            } else {
                this.errorNtf("Something went wrong!", "Internal server error!");
            }
            
            this.$emit('saved', false);
        }
    }
}
</script>
