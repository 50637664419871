<template>
    <b-modal :id="modalId" title="Delete Video">
        <b-container fluid>
            <div class="alert alert-warning">
                <h3 class="h4">Attention!</h3>
                <p>Your video is going to be deleted. Are you shure?</p>
            </div>
        </b-container>    
        <template v-slot:modal-footer="{ cancel }">
            <b-button variant="danger" @click="drop">Delete</b-button>
            <b-button  @click="cancel()">Cancel</b-button>
        </template>
    </b-modal>  
</template>
<script>
import { notificationMixin } from './../../mixins/notification.mixin';

export default {
    data() {
        return {
            modalId: 'delete-video',
            videoId: null
        }
    },
    mixins: [notificationMixin],
    methods: {
        show() {
            this.$bvModal.show(this.modalId);
        },
        drop() {
            this.$http.delete('video/' + this.videoId)
                .then(this.responseSuccess, this.responseError);
        },
        responseSuccess() {
            this.$bvModal.hide(this.modalId);
            this.successNtf("Success!", "Video was deleted succesfully!");
            this.$emit('saved', true);
        },
        responseError() {
            this.errorNtf("Something went wrong!", "Internal server error!");
            this.$emit('saved', false);
        }
    }
}
</script>
